import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import DashboardService from '../services/dashboard.service';
import RegistrationGraph from './dashboard/RegistrationGraph';

const initialMonthOverview = {
  newCustomers: 0,
  newProducts: 0,
  salesCount: 0,
  salesAmount: 0
};

const initialRegistrationData = [];

const Dashboard = () => {
  const [monthOverview, setMonthOverview] = useState(initialMonthOverview);
  const [registrationData, setRegistrationData] = useState(initialRegistrationData);

  useEffect(() => {
    DashboardService.get()
      .then((response) => {
        if (response.data.success) {
          setMonthOverview(response.data.monthOverview);
          setRegistrationData(response.data.last6Months);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
        Current Month Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                New Signups this Month
              </Typography>
              <Typography variant="h4">{monthOverview.newCustomers}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                New Products this Month
              </Typography>
              <Typography variant="h4">{monthOverview.newProducts}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                Quantity of Sales this Month
              </Typography>
              <Typography variant="h4">{monthOverview.salesCount}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                Amount of Sales this Month
              </Typography>
              <Typography variant="h4">${(monthOverview.salesAmount / 100).toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} sx={{ height: '400px' }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            Signups & Conversions Trends
          </Typography>
          <RegistrationGraph data={registrationData} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
